import { GetStaticPaths } from 'next';
import { RegularPage, getStaticProps as getProps } from '../../layout/page';
import { getInitialShop } from '../../data/shop';
import { logForDebug } from '../../utils/helpers';
import { CONTENT_PAGE_SLUGS } from 'utils/constants';

export const getStaticProps = getProps;

export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
  const paths = [];
  const urls = [];
  const {
    shop: { buildPages },
    pages,
  } = await getInitialShop('/', 'da');

  locales.forEach((locale) => {
    CONTENT_PAGE_SLUGS.forEach((contentPage) => {
      urls.push(`/${locale}/${contentPage}`);
      paths.push({ params: { slug1: contentPage }, locale });
    });
    pages
      .filter((p) => p.type !== 'link')
      .forEach((p) => {
        urls.push(`/${locale}/${p.slug}`);
        paths.push({ params: { slug1: `${p.slug}` }, locale });
      });
  });

  urls.forEach((url) => logForDebug(`[slug1]/index => ${url}`));

  return {
    paths: buildPages ? urls : [], // urls,
    // paths: [], // urls,
    // paths: urls,
    fallback: true,
  };
};
export default RegularPage;
